import {unregister} from "./serviceWorker"
import React from "react"
import {createRoot} from "react-dom/client"
import "./index.css"
import "moment/locale/de"
import "assets/semantic/sui-ncg-theme.css"
import "./App.css"
import "./tachyons.css"
import "./assets/animate.css"
import "./assets/semantic.overwrite.css"
import * as Sentry from "@sentry/browser"
import {Integrations} from "@sentry/tracing"
import {CaptureConsole} from "@sentry/integrations"
import {toast} from "react-toastify"
import asyncComponent from "./utils/AsyncComponent"
import shop from "store/shop"
import {Route, Switch} from "react-router"
import {BrowserRouter as Router} from "react-router-dom"
import {configure} from "mobx"

console.log({shop})
const App = asyncComponent(() => import( "./App"))
unregister()
window["toast"] = toast

// TODO: Refactor into actions and set to observe
// https://mobx.js.org/configuration.html
configure({
    enforceActions: "never"
})

if (document.location.hostname !== "localhost") {
    const usesProductionAPI_URL = process.env.REACT_APP_API_URL === "https://shop.auto-nagel.de"
    if (document.location.hostname !== "www.auto-nagel.de" && usesProductionAPI_URL) {
        //document.location.replace("https://www.auto-nagel.de" + document.location.pathname)
    }

    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_URL || "https://a4c2d605f53148f0878bfd438af3afae@o1147161.ingest.sentry.io/6289065",
        integrations: [
            new CaptureConsole({
                levels: ["error"]
            }),
            new Integrations.BrowserTracing(),
            new Sentry.Replay({
                // Additional SDK configuration goes in here, for example:
                maskAllText: true,
                blockAllMedia: true,
            }),
        ],
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
        environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || "production",
        release: `${process.env.REACT_APP_VERSION_NUMBER}-${process.env.REACT_APP_GIT_SHA}`,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0
    })

}

// POLYFILLS
if (!Array.from) {
    Array.from = function (object) {
        
        return [].slice.call(object)
    }
}

// Object.values && Object.entries() polyfill
// require( 'es7-object-polyfill' )
/**
 * String.prototype.replaceAll() polyfill
 * https://gomakethings.com/how-to-replace-a-section-of-a-string-with-another-one-with-vanilla-js/
 * @author Chris Ferdinandi
 * @license MIT
 */
if (!String.prototype.replaceAll) {
    String.prototype.replaceAll = function (str, newStr) {

        // If a regex pattern
        if (Object.prototype.toString.call(str).toLowerCase() === "[object regexp]") {
            return this.replace(str, newStr)
        }

        // If a string
        return this.replace(new RegExp(str, "g"), newStr)
    }
}

const container = document.getElementById("ncg-app")
const root = createRoot(container)
root.render(<Router basename={"/shop"}>
    <Switch>
        <Route path={"/"} component={App}/>
    </Switch>
</Router>)

